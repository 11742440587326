import React from 'react';
import { graphql } from 'gatsby';

import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
// import Sky from 'react-sky';
// import amex from '../img/amex.svg';
// import discover from '../img/discover.svg';
// import mastercard from '../img/mastercard.svg';
// import visa from '../img/visa.svg';

import { Button, Columns, Column, Section } from '../components/bulma';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
// import Slideshow from '../components/Slideshow';
import Blurbs from '../components/Blurbs';
import ContentSection from '../components/ContentSection';
import FinalSection from '../components/FinalSection';
import BlogSection from '../components/BlogSection';
import CTA from '../components/CTA';

export default function IndexPage({ data }) {
	const { markdownRemark: page } = data;
	const { image, heading, subheading, sections, blurbs, final } = page.frontmatter;

	return (
		<Layout>
			<IndexPageTemplate
				image={image}
				heading={heading}
				subheading={subheading}
				blurbs={blurbs}
				sections={sections}
				final={final}
			/>
		</Layout>
	);
}

export const IndexPageTemplate = ({ image, heading, subheading, sections, blurbs, final }) => (
	<div>
		<Hero size="medium" bold title={heading} subtitle={subheading} image={image} signup />
		<Blurbs box items={blurbs} />
		<ContentSection items={sections} box />
		{/* <BlogSection /> */}
		<Section>
			<CTA
				box
				bold
				color="black"
				title={final.title}
				subtitle={final.text}
				cta={{ label: 'Start free', url: '#' }}
			/>
		</Section>
	</div>
);

export const indexPageQuery = graphql`
	query IndexPage {
		markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
			frontmatter {
				title
				heading
				subheading
				image {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				blurbs {
					image {
						childImageSharp {
							fluid(maxWidth: 240, quality: 64) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
					title
					text
				}
				sections {
					title
					text
					color
					side
					image {
						childImageSharp {
							fluid(maxWidth: 240, quality: 64) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
				}
				final {
					title
					text
					# image {
					# 	childImageSharp {
					# 		fluid(maxWidth: 240, quality: 64) {
					# 			...GatsbyImageSharpFluid_withWebp
					# 		}
					# 	}
					# }
				}
			}
		}
	}
`;
